import { Row, Button, FormControl } from 'react-bootstrap'
import React, { useState } from 'react'
import Select from 'react-select'

export function StepCodigoInterno ({ next, back, data }) {
  const [codigoInterno, setCodigoInterno] = useState(data.codigoInterno || '')

  const handleNext = () => {
    next({ codigoInterno })
  }

  return (
    <div>
      <div className='text-center mb-4'>Código interno:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='codigoInterno'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Código do Produto'
            value={codigoInterno}
            autoComplete='off'
            onChange={(e) => setCodigoInterno(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={back} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </div>
  )
}

export function StepNomeProduto ({ next, back, data }) {
  const [nomeProduto, setNomeProduto] = useState(data.nomeProduto || '')

  const handleNext = () => {
    next({ nomeProduto })
  }

  return (
    <div>
      <div className='text-center mb-4'>Nome do Produto:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='nomeProduto'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Nome do Produto'
            value={nomeProduto}
            autoComplete='off'
            onChange={(e) => setNomeProduto(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={back} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </div>
  )
}

export function StepCodigoBarras ({ next, back, data }) {
  const [codigoBarras, setCodigoBarras] = useState(data.codigoBarras || '')

  const handleNext = () => {
    next({ codigoBarras })
  }

  return (
    <div>
      <div className='text-center mb-4'>Código de Barras:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='codigoBarras'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Código de Barras'
            value={codigoBarras}
            autoComplete='off'
            onChange={(e) => setCodigoBarras(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={back} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </div>
  )
}

export function StepDescricao ({ next, back, data }) {
  const [descricao, setDescricao] = useState(data.descricao || '')

  const handleNext = () => {
    next({ descricao })
  }

  return (
    <div>
      <div className='text-center mb-4'>Descrição:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='descricao'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Descrição'
            value={descricao}
            autoComplete='off'
            onChange={(e) => setDescricao(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={back} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </div>
  )
}

export function StepPrecoBase ({ next, back, data }) {
  const [precoBase, setPrecoBase] = useState(data.precoBase || 0)

  const handleNext = () => {
    next({ precoBase })
  }

  return (
    <div>
      <div className='text-center mb-4'>Preço Base:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='precoBase'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Preço Base'
            type='number'
            value={precoBase}
            autoComplete='off'
            onChange={(e) => setPrecoBase(parseFloat(e.target.value) || 0)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={back} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </div>
  )
}

export function StepUnidadeBase ({ next, back, data }) {
  const [unidadeBase, setUnidadeBase] = useState(data.unidadeBase || 'KG')
  const unidadesBase = ['CX', 'KG', 'UN', 'SACO']

  const handleNext = () => {
    next({ unidadeBase })
  }

  const unidades = unidadesBase.map((item) => ({
    value: item,
    label: item
  }))

  return (
    <div>
      <div className='text-center mb-4'>Unidade Base:</div>
      <div className='d-flex flex-column align-items-center'>
        <div name='unidadeBase' style={{ width: '200px' }}>
          <Select placeholder='Medida' options={unidades} value={{ value: unidadeBase, label: `Medida: ${unidadeBase}` }} onChange={(e) => setUnidadeBase(e.value)} />
        </div>
      </div>
      <div className='mt-5 pt-5'>
        <Row className='justify-content-center'>
          <Button onClick={back} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='finalizar' onClick={handleNext} variant='dark'>
            Finalizar
          </Button>
        </Row>
      </div>
    </div>
  )
}
