import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getDateString, getDateTime } from 'util/date'

function getVencimento (produto) {
  if (produto.vencimento) {
    return getDateTime(produto.vencimento)
  }

  if (produto.ultimaModificacao && produto.ultimaModificacao.validade) {
    return getDateTime(produto.ultimaModificacao.validade)
  }

  return undefined
}

function produtoPropsToState (produto) {
  const { vencimento, ultimaModificacao, produtos } = produto
  return {
    vencimento: vencimento ? getDateTime(vencimento) : ultimaModificacao?.validade ? getDateTime(ultimaModificacao.validade) : undefined,
    produtos: produtos
  }
}

const getCssDoCard = (validade, diasParaVencer) => {
  if (!validade) return { cssBox: 'bg-dark text-white', color: 'dark' }
  if (diasParaVencer < 0) return { cssBox: 'bg-danger text-white', color: 'danger' }
  if (diasParaVencer < 3) return { cssBox: 'bg-semi-danger text-black', color: 'semi-danger' }
  if (diasParaVencer < 7) return { cssBox: 'bg-warning text-black', color: 'warning' }
  return { cssBox: 'bg-dark text-white', color: 'dark' }
}

const RenderCard = ({ produto, onEdit, exibirEditar }) => {
  const validade = produto.vencimento ? dayjs(produto.vencimento) : undefined
  const diasParaVencer = validade ? dayjs(validade).diff(dayjs(), 'days') || 0 : 0
  const { cssBox, color } = getCssDoCard(validade, diasParaVencer)

  return (
    <Card
      key={`card-produto-${produto.id}`}
      className='mb-3 shadow-xl'
      style={{
        maxWidth: '305px',
        minWidth: '265px',
        border: '.5px solid #eee',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)'
      }}
    >
      <Card.Header className={cssBox} as='h6'>
        <div className='pt-2'>{produto.nomeProduto}</div>
      </Card.Header>
      <Card.Body>
        <Container className='d-flex flex-column h-100'>
          <Row className='text-secondary pl-1 mb-2'>
            <Col>Unidade</Col>
            <Col>{produto.unidade || 'Sem Unidade'}</Col>
          </Row>
          <Row className='text-secondary pl-1 mb-2'>
            <Col>Quantidade</Col>
            <Col>{produto.quantidadeAtual.toLocaleString('pt-br') || 'Sem Quantidade'}</Col>
          </Row>
          <Row className='text-secondary pl-1 mb-2'>
            <Col>Validade</Col>
            <Col>{validade ? validade.format('DD/MM/YYYY') : 'Sem Validade'}</Col>
          </Row>
          {diasParaVencer > 0 && (
            <Row className='text-secondary pl-1 mb-2'>
              <Col>Dias para vencer</Col>
              <Col>{diasParaVencer}</Col>
            </Row>
          )}
          {!exibirEditar && (
            <div className='mt-auto'>
              <Button
                onClick={() =>
                  onEdit({
                    ...produto,
                    validade: validade ? getDateString(validade) : undefined
                  })
                }
                variant={color}
                block
                style={{ fontSize: 14 }}
                className='py-2 mb-3'
              >
                <b>Editar</b>
              </Button>
            </div>
          )}
        </Container>
      </Card.Body>
    </Card>
  )
}

function BoxProdutoCompradorDonatario ({ produto, exibirEditar, onEdit }) {
  const [dados, setDados] = useState(produtoPropsToState(produto))

  useEffect(() => {
    setDados(produtoPropsToState(produto))
  }, [produto])

  return (
    <>
      {dados.produtos.map((produto) => (
        <RenderCard key={produto.id} produto={produto} exibirEditar={exibirEditar} onEdit={onEdit} />
      ))}
    </>
  )
}

BoxProdutoCompradorDonatario.propTypes = {
  produto: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,

  exibirEditar: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(BoxProdutoCompradorDonatario)
