import React, { useState, useCallback } from 'react'

export function StepHandler ({ steps, initialData, onFinish, renderExtra }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [data, setData] = useState(initialData)

  const next = useCallback((payload) => {
    setData((prev) => ({ ...prev, ...payload }))
    setCurrentStep((prev) => prev + 1)
  }, [])

  const back = useCallback((payload) => {
    setData((prev) => ({ ...prev, ...payload }))
    setCurrentStep((prev) => prev - 1)
  }, [])

  const finish = useCallback(
    (payload) => {
      const finalData = { ...data, ...payload }

      onFinish(finalData)
    },
    [data, onFinish]
  )

  const CurrentStepComponent = steps[currentStep - 1]

  return (
    <div>
      {renderExtra && renderExtra(data, setCurrentStep)}

      <CurrentStepComponent next={currentStep === steps.length ? finish : next} back={back} data={data} />
    </div>
  )
}
