import TelaDeErro from 'components/telaDeErro'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { isNumber } from 'lodash'
import { useMemo, useState } from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import { getDateString, getDateTimeString, getNow, isDateValid } from 'util/date'
import { baixarPlanilha, gerarPlanilha } from 'util/excelAdapter'
import { handleOnFileChangeEventAsync } from 'util/importar-produtos-lote'
import { isArrayNotEmpty } from 'util/utils'
dayjs.extend(isSameOrAfter)

export const ExcelButtons = ({ produtosComEstoque, produtosSemEstoque, onImport, isCatalogo }) => {
  const [showEmptyFileAlert, setShowEmptyFileAlert] = useState(false)
  const [error, setError] = useState(null)
  const produtos = [...produtosComEstoque, ...produtosSemEstoque]

  const prods = produtos.map(({ unidadeBase, idProduto, idEmpresa, estoque, codigoInterno, nomeProduto, precoBase, ultimaModificacao }) => {
    const numLote = ultimaModificacao ? ultimaModificacao.numLote : ''
    const vencimento = ultimaModificacao ? ultimaModificacao.validade : ''
    const vencimentoFormatado = isDateValid(vencimento) ? getDateTimeString(vencimento) : ''
    return {
      idEmpresa,
      idProduto,
      codigoInterno,
      nomeProduto,
      quantidade: Number(estoque) || 0,
      precoBase: Number(precoBase) || 0,
      unidadeBase,
      vencimento: vencimentoFormatado,
      numLote
    }
  })

  const formatProdCatalogo = ({ codigoInterno, nomeProduto, codigoDeBarras, descricao, precoBase, unidadeBase, vencimento, numLote }) => ({
    codigoInterno: codigoInterno.toString(),
    nomeProduto: typeof nomeProduto === 'string' ? nomeProduto.trim() : nomeProduto,
    descricao: typeof descricao === 'string' ? descricao.trim() : '',
    codigoDeBarras,
    precoBase: Number(precoBase) || 0,
    unidadeBase,
    vencimento,
    numLote
  })

  const toDate = (excelDate) => {
    if (!excelDate) return undefined

    if (isNumber(excelDate)) {
      return new Date((excelDate - (25567 + 2)) * 86400 * 1000)
    }

    const formattedDate = getDateString(excelDate)
    const date = dayjs(formattedDate).startOf('day')
    const now = getNow()

    if (!isDateValid(date)) return undefined

    const isHojeOuDepois = date.isSameOrAfter(now, 'day')

    return isHojeOuDepois ? date.toDate() : undefined
  }

  const formatProd = (row) => {
    if (!row || !row.nomeProduto) {
      return {}
    }

    return {
      codigoInterno: row.codigoInterno.toString(),
      nomeProduto: row.nomeProduto.trim(),
      quantidade: Number(row.quantidade) || Number(row.estoque) || 0,
      precoBase: Number(row.precoBase) || 0,
      unidadeBase: row.unidadeBase,
      vencimento: toDate(row.vencimento) || undefined,
      numLote: row.numLote || undefined
    }
  }

  const filtrarAlteracoes = (rowsPlanilha, produtos, condicao) => {
    return rowsPlanilha.filter((row) => {
      const produtoFormatado = formatProd(row)
      const produtoExistente = produtos.find(condicao(produtoFormatado))
      if (!produtoExistente) return false
      return Object.entries(produtoFormatado).some(([key, value]) => produtoExistente[key] !== value)
    })
  }

  const importarPlanilhaCallBack = (rowsPlanilha, isCatalogo) => {
    if (!isArrayNotEmpty(rowsPlanilha)) {
      setShowEmptyFileAlert(true)
      return
    }

    setShowEmptyFileAlert(false)

    try {
      if (isCatalogo) {
        const prods = rowsPlanilha.map(formatProdCatalogo)
        onImport(prods, isCatalogo)
        return
      }

      const alteracoesComEstoque = filtrarAlteracoes(rowsPlanilha, produtosComEstoque, (produtoFormatado) => (produto) => produto.codigoInterno === produtoFormatado.codigoInterno)

      const alteracoesSemEstoque = filtrarAlteracoes(rowsPlanilha, produtosSemEstoque, (produtoFormatado) => (produto) =>
        produto.codigoInterno === produtoFormatado.codigoInterno && produto.nomeProduto === produtoFormatado.nomeProduto
      )

      const produtosNaoCadastrados = rowsPlanilha.filter((row) => {
        const produtoFormatado = formatProd(row)
        return !produtos.find((produto) => produto.codigoInterno === produtoFormatado.codigoInterno && produto.nomeProduto === produtoFormatado.nomeProduto)
      })

      const formatarAlteracoes = (alteracoes, produtos) =>
        alteracoes.map((produto) => {
          const [prod] = produtos.filter((prod) => prod.codigoInterno === produto.codigoInterno)
          return { ...prod, ...produto }
        })

      const payload = {
        alteracoesComEstoque: formatarAlteracoes(alteracoesComEstoque, produtosComEstoque),
        alteracoesSemEstoque: formatarAlteracoes(alteracoesSemEstoque, produtosSemEstoque),
        produtosNaoCadastrados: formatarAlteracoes(produtosNaoCadastrados, produtosSemEstoque),
        quantidadeAlteracoes: alteracoesComEstoque.length + alteracoesSemEstoque.length + produtosNaoCadastrados.length
      }

      onImport(payload)
    } catch (error) {
      console.error('Ocorreu um erro ao importar a planilha:', error)
      setError({
        message: 'Ocorreu um erro ao importar a planilha. Por favor, tente novamente.',
        details: error.message || 'Erro desconhecido.'
      })
    }
  }

  const handleDownload = async () => {
    const workbook = await gerarPlanilha(prods, isCatalogo)
    const fileName = isCatalogo ? 'Planilha de Inclusão de Produtos' : 'Planilha Atualização de Produtos'
    await baixarPlanilha(workbook, fileName)
  }

  return (
    <div>
      {error ? (
        <TelaDeErro error={error.details} message={error.message} callbackReturn={() => setError(null)} />
      ) : (
        <Row className='float-right mb-3'>
          <label htmlFor={`file-upload${isCatalogo ? 1 : 0}`} className='btn saveadd-primary-color' data-testid={`upload-button-${isCatalogo ? 'catalogo' : 'estoque'}`}>
            Enviar Planilha
          </label>
          <input
            type='file'
            id={`file-upload${isCatalogo ? 1 : 0}`}
            name={`file-upload${isCatalogo ? 1 : 0}`}
            onChange={async (event) => {
              event.persist()
              try {
                await handleOnFileChangeEventAsync(
                  event,
                  (e) => {
                    importarPlanilhaCallBack(e, isCatalogo)
                  },
                  isCatalogo
                )
                event.target.value = null
              } catch (error) {
                console.error('Erro ao processar o arquivo:', error)
                setError({
                  message: 'Erro ao processar o arquivo.',
                  details: error.message || 'Erro desconhecido.'
                })
              }
            }}
          />
          <Button className='btn btn-primary' onClick={handleDownload}>
            {isCatalogo ? 'Baixar planilha de Catálogo' : 'Baixar planilha de Estoque'}
          </Button>

          <Modal show={showEmptyFileAlert} onHide={() => setShowEmptyFileAlert(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Atenção</Modal.Title>
            </Modal.Header>
            <Modal.Body>O arquivo que você tentou importar está vazio. Por favor selecione outro arquivo.</Modal.Body>
            <Modal.Footer>
              <Button variant='primary' onClick={() => setShowEmptyFileAlert(false)}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      )}
    </div>
  )
}

export default ExcelButtons
