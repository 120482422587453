import Loading from 'components/loading'
import TabHandler from 'components/produto/tabHandler'
import { Screen } from 'components/style'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { loadProdutos as loadProdutoEstoque } from 'store/estoque-comprador/actions'
import { loadProdutos } from 'store/produto/actions'
import 'styles/product.css'
import TelaCatalogo from '../../pages/catalogo'
import TelaEstoque from '../../pages/estoque'
import TelaRastreioComprador from '../rastreio-comprador'
function ListProdutos ({ produtos, produtosComprador, categorias, dispatch, isLoadingProdutos, isClient, perfil, isDeleting }) {
  useEffect(() => {
    if (isClient) {
      const { doacao } = perfil
      const tipoPerfil = doacao.donatario ? 'donatario' : 'comprador'
      dispatch(loadProdutoEstoque(tipoPerfil))
    } else {
      dispatch(loadProdutos())
    }
  }, [dispatch, isClient, perfil])

  const [currentTab, setCurrentTab] = useState(1)
  const isCatalogo = currentTab === 0
  const renderProduto = () => <TelaCatalogo isCatalogo={isCatalogo} />
  const renderEstoque = () => {
    return <TelaEstoque produtos={produtos} categorias={categorias} isCatalogo={isCatalogo} />
  }

  if (isLoadingProdutos || isDeleting) {
    return <Loading />
  }

  const getTabs = () => [
    {
      display: 'Catálogo',
      callback: renderProduto
    },
    {
      display: 'Estoque',
      callback: renderEstoque
    }
  ]
  const tabs = getTabs()
  return (
    <Screen back={{ to: '/', title: 'Menu' }}>
      {isClient ? (
        <div name='catalogo' className='p-3'>
          <TelaRastreioComprador produtos={produtosComprador} categorias={categorias} isCatalogo={isCatalogo} />
        </div>
      ) : (
        <TabHandler tabs={tabs} updateTab={setCurrentTab} />
      )}
    </Screen>
  )
}

const mapStateToProps = (state) => {
  const { doacao, venda } = state.perfil

  return {
    categorias: state.produto.categorias,
    produtos: state.produto.produtos,
    produtosComprador: state.estoqueComprador.produtos,
    isLoadingProdutos: state.produto.isLoadingProdutos,
    isDeleting: state.produto.isDeleting,
    perfil: state.perfil,
    isClient: doacao.donatario || venda.comprador
  }
}
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ListProdutos)
