import React from 'react'
import { connect } from 'react-redux'
import { addMovimentoEstoqueLote } from 'store/produto/actions'
import Loading from 'components/loading'
import TelaDeErro from 'components/telaDeErro'
import { BtnSalvar, BtnSalvarVoltar } from 'components/Botao'
import { isArrayNotEmpty } from 'util/utils'
import { Container, CardDeck } from 'react-bootstrap'
import SearchBar from 'components/search-bar'
import { getProdutosFiltrados } from 'util/importar-produtos-lote'
import BoxProduto from 'components/produto/boxProdutoEstoque'
import { push } from 'connected-react-router'
import { ExcelButtons } from 'components/estoque/ExcelButtons'
import dayjs from 'dayjs'
import 'styles/produto.css'
import { toSafeInteger } from 'lodash'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

const searchOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.4,
  keys: [
    { name: 'nomeProduto', weight: 2 },
    { name: 'descricaoProduto', weight: 0.6 },
    { name: 'categoriaEntity.nomeCategoria', weight: 0.4 }
  ]
}

class TelaEstoque extends React.Component {
  constructor (props) {
    super(props)
    const { produtos } = this.props
    this.elementoTopo = React.createRef()

    this.state = {
      produtosComEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosComEstoqueFiltrados: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoqueFiltrados: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosAlterados: {},
      produtosNaoCadastrados: [],
      categoria: {},
      hasSearch: false,
      subCategoria: {},
      inputFileFieldRef: Math.random().toString(36),
      expireDays: 0,
      hasChanges: true,
      isLoading: false,
      apiError: null,
      etapaInicial: 1,
      etapaAtiva: 1
    }

    this.displayProdutosList = this.displayProdutosList.bind(this)
  }

  resetApiError = () => {
    this.setState({ apiError: null })
  }

  componentDidUpdate (prevProps, prevState) {
    const { produtos, isEstoqueLotePosting } = this.props
    if (produtos !== prevProps.produtos) {
      this.setProdutosFiltrados()
    }

    if (prevProps.isEstoqueLotePosting && !isEstoqueLotePosting) {
      this.setState({
        etapaAtiva: 1,
        produtosComEstoque: [],
        produtosSemEstoque: [],
        produtosAlterados: {},
        isLoading: false
      })
    }
  }

  handleEdit = (produto) => {
    const { dispatch } = this.props

    localStorage.removeItem('produto')
    localStorage.setItem('produto', JSON.stringify(produto))
    setTimeout(() => {
      dispatch(push(`/produtos/edit/${produto.idProduto}`))
    }, 10)
  }

  displayProdutosList (produtos, onChange, listIdentifier) {
    if (isArrayNotEmpty(produtos)) {
      return produtos.map((produto, i) => {
        const hasChange = this.state.produtosAlterados[produto.idProduto]
        if (hasChange) {
          produto = { ...hasChange, ...produto }
        }
        const uniqueKey = `${produto.idProduto}_${listIdentifier}`
        return <BoxProduto key={uniqueKey} produto={produto} i={i} onChange={onChange} onEdit={this.handleEdit} hide={this.state.etapaAtiva !== 1} />
      })
    } else {
      return <>Não existem produtos na categoria selecionada</>
    }
  }

  getProdutosFiltrados = (produtos) => {
    return getProdutosFiltrados(produtos, this.props.categorias, this.state.categoria, this.state.subCategoria)
  }

  setProdutosFiltrados = () => {
    this.setState({
      produtosComEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque <= 0))
    })
  }

  handleUpdateProduto = (dados, callback) => {
    try {
      const { produtos } = this.props
      const produtoAlterado = produtos.some((el) => el.idProduto === dados.idProduto || (el.codigoInterno && el.codigoInterno === dados.codigoInterno))
      if (produtoAlterado) {
        this.setState(
          (prevState) => ({
            produtosAlterados: {
              ...prevState.produtosAlterados,
              [produtoAlterado.idProduto]: { ...produtoAlterado, ...dados }
            }
          }),
          callback
        )
      }
    } catch (error) {
      console.error('Erro ao atualizar o produto:', error)
      this.setState({ error: error.message || 'Erro desconhecido' })
    }
  }

  hasInvalidItens = () => {
    return this.state.produtosAlterados.some((item) => item.invalid)
  }

  criarPayload = (produtos) => {
    return produtos.map((produto) => ({
      unidade: produto.unidadeBase,
      preco: produto.precoBase,
      idProduto: produto.idProduto,
      idLote: produto.idLote,
      motivo: produto.motivo,
      qrCode: produto.qrCode,
      numLote: produto.numLote,
      codigoInterno: produto.codigoInterno,
      validade: produto.vencimento ? dayjs(produto.vencimento).format('YYYY-MM-DDTHH:mm') : undefined,
      tipo: 'substituir',
      quantidade: produto.quantidade,
      nomeProduto: produto.nomeProduto
    }))
  }

  renderCardDeck = (produtosAlterados) => (
    <CardDeck style={{ justifyContent: 'center' }}>
      {Object.values(produtosAlterados).map((produto, i) => (
        <BoxProduto key={produto.idProduto || i} produto={produto} exibirEditar={false} />
      ))}
    </CardDeck>
  )

  renderConteudoContainer = (title, description, produtosAlterados) => (
    <Container>
      <h5>{title}</h5>
      <h6>{description}</h6>
      {this.renderCardDeck(produtosAlterados)}
    </Container>
  )

  botaoSalvar = () => <BtnSalvar onSalvar={() => this.setState({ etapaAtiva: 2 })} disabled={this.hasInvalidItens()} />

  salvarNavegar = (payload, nextStage) => {
    const { dispatch } = this.props

    dispatch(addMovimentoEstoqueLote(payload, 'Upload de planilha manual'))
      .then(() => {
        this.setState({ etapaAtiva: nextStage })
        this.setState({ error: null })
      })
      .catch((error) => {
        console.error('Erro ao salvar as alterações:', error)
        this.setState({ error: error.message || 'Erro desconhecido' })
      })
  }

  estagioProdutosNaoCadastrados = () => {
    const { produtosNaoCadastrados, produtosAlterados } = this.state

    const payload = this.criarPayload(produtosAlterados)

    return (
      <Container>
        {this.renderConteudoContainer(
          'Atenção! Alguns produtos não estão cadastrados',
          'Os itens abaixo não constam no sistema. Caso deseja salvar os demais itens, aperte em salvar.',
          produtosNaoCadastrados
        )}
        <h6>Para cadastrar esses itens siga o caminho a seguir: Estoque &gt; aba Catálogo &gt; botão Novo Produto</h6>
        <hr />
        {this.renderConteudoContainer('Produtos Alterados', 'Verifique se as alterações estão corretas antes de salvar', produtosAlterados)}
        <BtnSalvarVoltar onVoltar={() => this.setState({ etapaAtiva: 1 })} onSalvar={() => this.salvarNavegar(payload, 1)} disabled={this.hasInvalidItens()} />
      </Container>
    )
  }

  estagioAdicionarAlteracoes = () => {
    const { produtosComEstoqueFiltrados, produtosSemEstoqueFiltrados, expireDays } = this.state

    const { isCatalogo } = this.props

    const changeItems = async ({ result, query }) => {
      this.setState({ isLoading: true })

      try {
        this.setState({
          hasSearch: !!query,
          produtosComEstoque: result.filter((x) => x.estoque > 0),
          produtosComEstoqueFiltrados: result.filter((x) => x.estoque > 0),
          produtosSemEstoque: result.filter((x) => x.estoque <= 0),
          produtosSemEstoqueFiltrados: result.filter((x) => x.estoque <= 0),
          apiError: null
        })
      } catch (error) {
        this.setState({
          apiError: [
            {
              campo: 'Mudança de Itens',
              mensagem: error.message || 'Ocorreu um erro ao mudar os itens.'
            }
          ]
        })
      } finally {
        this.setState({ isLoading: false })
      }
    }

    const onImport = ({ alteracoesComEstoque = [], alteracoesSemEstoque = [], quantidadeAlteracoes = 0, produtosNaoCadastrados = [] }) => {
      const { etapaAtiva } = this.state
      produtosNaoCadastrados

      try {
        if (!Array.isArray(alteracoesComEstoque)) {
          throw new TypeError('Houve um erro ao processar as alterações com estoque. Por favor, tente novamente.')
        }
        if (!Array.isArray(alteracoesSemEstoque)) {
          throw new TypeError('Houve um erro ao processar as alterações sem estoque. Por favor, tente novamente.')
        }
        if (typeof quantidadeAlteracoes !== 'number') {
          throw new TypeError('A quantidade de alterações é inválida. Certifique-se de que o valor inserido é um número.')
        }
        if (!Array.isArray(produtosNaoCadastrados)) {
          throw new TypeError('Houve um erro ao verificar os produtos não cadastrados. Por favor, tente novamente.')
        }

        if (produtosNaoCadastrados.length > 0) {
          this.setState({
            etapaAtiva: 3,
            produtosNaoCadastrados,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: true
          })
        } else if (quantidadeAlteracoes > 0) {
          this.setState({
            etapaAtiva: 2,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: true
          })
        } else {
          this.setState({
            etapaAtiva: 2,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: false
          })
        }
      } catch (error) {
        console.error('Erro durante importação:', error)
        this.setState({
          apiError: [
            {
              campo: 'Importação de Produtos',
              mensagem: 'Ocorreu um erro ao importar produtos.'
            }
          ]
        })
      }
    }

    const ButtonsExcel = ({ produtosComEstoque, produtosSemEstoque }) => {
      const importar = ({ alteracoesComEstoque, alteracoesSemEstoque, quantidadeAlteracoes, produtosNaoCadastrados }) => {
        onImport({
          alteracoesComEstoque,
          alteracoesSemEstoque,
          quantidadeAlteracoes,
          produtosNaoCadastrados
        })
      }
      return <ExcelButtons produtosComEstoque={produtosComEstoque} produtosSemEstoque={produtosSemEstoque} onImport={importar} isCatalogo={false} />
    }

    const setStateFiltro = (diasVencimento, produtosComEstoque, produtosSemEstoque) =>
      this.setState({
        expireDays: diasVencimento,
        produtosComEstoqueFiltrados: produtosComEstoque,
        produtosSemEstoqueFiltrados: produtosSemEstoque,
        apiError: null
      })

    const removerFiltro = () => {
      const { produtosComEstoque, produtosSemEstoque } = this.state
      setStateFiltro(0, produtosComEstoque, produtosSemEstoque)
    }

    const isAfter = (vencimento, limite) => vencimento && dayjs(vencimento).isAfter(limite)
    const isBefore = (vencimento, limite) => vencimento && dayjs(vencimento).isBetween(dayjs(), limite, 'day', '[]')
    const filtrarProdutosAfter = (produtos, limite) => produtos.filter((p) => isAfter(p.vencimento, limite))
    const filtrarProdutosBefore = (produtos, limite) => produtos.filter((p) => isBefore(p.vencimento, limite))

    const filtrarProdutosAposLimite = (dias) => {
      const { produtosComEstoque, produtosSemEstoque } = this.state
      const limite = dayjs().add(dias - 1, 'day')

      setStateFiltro(dias, filtrarProdutosAfter(produtosComEstoque, limite), filtrarProdutosAfter(produtosSemEstoque, limite))
    }

    const filtrarProdutosAntesLimite = (dias) => {
      const { produtosComEstoque, produtosSemEstoque } = this.state
      const limite = dayjs().add(Math.abs(dias), 'day')

      setStateFiltro(dias, filtrarProdutosBefore(produtosComEstoque, limite), filtrarProdutosBefore(produtosSemEstoque, limite))
    }

    const filtrarProduto = (event) => {
      this.setState({ isLoading: true })

      try {
        const diasVencimento = toSafeInteger(event.target.value)
        if (diasVencimento === 0) removerFiltro()
        else if (diasVencimento > 0) filtrarProdutosAposLimite(diasVencimento)
        else filtrarProdutosAntesLimite(diasVencimento)
      } catch (error) {
        console.error(error)
        this.setState({
          apiError: [
            {
              campo: 'Filtro por Vencimento',
              mensagem: error.message || 'Ocorreu um erro ao filtrar produtos por vencimento.'
            }
          ]
        })
      } finally {
        this.setState({ isLoading: false })
      }
    }

    const Filter = () => {
      return (
        <div className='d-flex align-items-center mt-3 pl-1'>
          <div>Vence em:</div>
          <div className='ml-2'>
            <select onChange={filtrarProduto} className='form-control' value={expireDays}>
              <option value='0'>Sem Limite</option>
              <option value='7'>Mais de 7 dias</option>
              <option value='15'>Mais de 15 dias</option>
              <option value='30'>Mais de 30 dias</option>
              <option value='60'>Mais de 60 dias</option>
              <option value='-7'>Menos de 7 dias</option>
              <option value='-15'>Menos de 15 dias</option>
              <option value='-30'>Menos de 30 dias</option>
              <option value='-60'>Menos de 60 dias</option>
            </select>
          </div>
        </div>
      )
    }
    return (
      <div>
        <ButtonsExcel produtosComEstoque={produtosComEstoqueFiltrados} produtosSemEstoque={produtosSemEstoqueFiltrados} isCatalogo={isCatalogo} />
        <SearchBar hasSearch={this.state.hasSearch} items={this.props.produtos} options={searchOptions} placeholder='Procurar em estoque' onSearchItems={changeItems} />
        <Filter />
        <hr />
        <h6 className='mb-3'>Produtos com Estoque:</h6>
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosComEstoqueFiltrados, this.handleUpdateProduto, 'comEstoque')}</CardDeck>
        <hr />
        <h6 className='mb-3'>Produtos sem Estoque:</h6>
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosSemEstoqueFiltrados, this.handleUpdateProduto, 'semEstoque')}</CardDeck>
      </div>
    )
  }

  estagioValidarAlteracoes = () => {
    const { produtosAlterados = [], produtosNaoCadastrados = [] } = this.state

    const produtosParaValidar = produtosAlterados.filter((produto) => !produtosNaoCadastrados.some((produtoNaoCadastrado) => produtoNaoCadastrado.idProduto === produto.idProduto))

    if (produtosParaValidar.length === 0) {
      return (
        <Container>
          {this.renderConteudoContainer('Nenhum produto alterado na tabela fornecida', 'Não há alterações para validar. Retorne para a aba estoque.', [])}
          <BotaoVoltar onVoltar={() => this.setState({ etapaAtiva: 1 })} />
        </Container>
      )
    }

    const payload = this.criarPayload(produtosParaValidar)

    return (
      <Container>
        {this.renderConteudoContainer('Produtos Alterados', 'Verifique se as alterações estão corretas antes de salvar', produtosParaValidar)}
        <BtnSalvarVoltar onVoltar={() => this.setState({ etapaAtiva: 1 })} onSalvar={() => this.salvarNavegar(payload, 1)} disabled={this.hasInvalidItens()} />
      </Container>
    )
  }

  render () {
    const { isEstoqueLotePosting } = this.props
    const { isLoading, apiError, etapaAtiva } = this.state

    if (isEstoqueLotePosting || isLoading) {
      return <Loading />
    }

    if (apiError) {
      return <TelaDeErro error={apiError} callbackReturn={this.resetApiError} />
    }

    return (
      <div ref={this.elementoTopo}>
        {etapaAtiva === 1 && this.estagioAdicionarAlteracoes()}
        {etapaAtiva === 2 && this.estagioValidarAlteracoes()}
        {etapaAtiva === 3 && this.estagioProdutosNaoCadastrados()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isEstoqueLotePosting: state.produto.isEstoqueLotePosting
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TelaEstoque)
