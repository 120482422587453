import React, { useMemo } from 'react'
import { StepHandler } from './steps'
import { StepCodigoBarras, StepCodigoInterno, StepDescricao, StepNomeProduto, StepPrecoBase, StepUnidadeBase } from 'components/estoque/catalogo/steps'
import { useHistory } from 'react-router-dom'
import { Screen } from 'components/style'
import { connect } from 'react-redux'
import { sendProduct } from 'store/importar-produtos-lote/actions'

const EditarProduto = ({ dispatch }) => {
  const history = useHistory()

  const initialData = {
    idProduto: undefined,
    codigoInterno: '',
    nomeProduto: '',
    codigoBarras: '',
    descricao: '',
    precoBase: 0,
    unidadeBase: 'KG'
  }

  const steps = useMemo(
    () => [
      (props) => <StepCodigoInterno {...props} />,
      (props) => <StepNomeProduto {...props} />,
      (props) => <StepCodigoBarras {...props} />,
      (props) => <StepDescricao {...props} />,
      (props) => <StepPrecoBase {...props} />,
      (props) => <StepUnidadeBase {...props} />
    ],
    []
  )

  const handleFinish = async (produto) => {
    try {
      await dispatch(sendProduct(produto.idProduto, produto))
    } catch (error) {}
    history.push('/produtos')
  }

  const positions = useMemo(
    () => [
      (produto) => !produto.codigoInterno.trim(),
      (produto) => !produto.nomeProduto.trim(),
      (produto) => !produto.codigoBarras.trim(),
      (produto) => !produto.descricao.trim(),
      (produto) => produto.precoBase <= 0,
      (produto) => !produto.unidadeBase.trim()
    ],
    []
  )

  const Positions = ({ data, setCurrentStep }) => {
    return positions.map((validate, indice) => {
      let classes = 'button-circle'
      if (validate(data)) {
        classes += ' button-circleRed'
      }
      if (indice !== 4) {
        classes += ' mr-1 mr-sm-3'
      }
      return (
        <div key={indice} onClick={() => setCurrentStep(indice + 1)} style={{ border: '2px solid #eee', cursor: 'pointer' }} className={classes} variant='outlined'>
          <div>{indice + 1}</div>
        </div>
      )
    })
  }

  return (
    <Screen back={{ to: '/produtos', title: 'Menu' }}>
      <div style={{ overflowX: 'hidden' }}>
        <div className='d-flex justify-content-center flex-wrap mt-4'>
          <StepHandler
            steps={steps}
            initialData={initialData}
            onFinish={handleFinish}
            renderExtra={(data, setCurrentStep) => {
              return (
                <div>
                  <div className='d-flex justify-content-center mb-4'>
                    <Positions data={data} setCurrentStep={setCurrentStep} />
                  </div>
                </div>
              )
            }}
          />
        </div>
      </div>
    </Screen>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(EditarProduto)
