import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isArrayEmpty } from 'util/utils'

import { Container, Button } from 'react-bootstrap'

const TelaDeErro = ({ error, callbackReturn, callbackReset, message, dispatch }) => {
  if (error === 'ECONNABORTED' || isArrayEmpty(error)) {
    return (
      <div className='container-success'>
        <div>
          <h1>O Servidor demorou muito a responder.</h1>
          <p>A demora em acessar o servidor pode ser algo momentâneo, por isso, tente novamente.</p>
          <p>Caso o erro persista, entre em contato com o suporte.</p>
          <Button
            className='btn btn-primary saveadd-primary-color'
            onClick={() => {
              dispatch({ type: 'RESETCONNECTION' })
              callbackReturn()
            }}
          >
            Tentar novamente
          </Button>
          <Button
            className='btn btn-primary saveadd-primary-color'
            onClick={() => {
              dispatch({ type: 'RESETCONNECTION' })
              callbackReset && callbackReset()
              callbackReturn()
            }}
          >
            Reiniciar
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Container>
      <h1>{message}</h1>
      {error.map((erro, index) => {
        return (
          <p key={JSON.stringify(erro)}>
            {index + 1}) {erro.campo} - {erro.mensagem}
          </p>
        )
      })}
      <Button
        onClick={() => {
          callbackReturn()
        }}
      >
        Voltar
      </Button>
    </Container>
  )
}

TelaDeErro.propTypes = {
  error: PropTypes.array,
  callbackReturn: PropTypes.func.isRequired,
  callbackReset: PropTypes.func,
  message: PropTypes.string.isRequired
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(TelaDeErro)
