import dayjs, { ConfigType, Dayjs } from 'dayjs'

export const getNow = (): Dayjs => dayjs(new Date())

export const getDate = (dateTime: ConfigType = new Date()): Dayjs => dayjs(dayjs(dateTime).format('YYYY-MM-DD'))

export const getDateTime = (dateTime?: ConfigType): string => {
  const dateToUse = dateTime ?? getNow()

  if (!isDateValid(dateToUse)) {
    throw new Error('Invalid date')
  }

  return dayjs(dateToUse)
    .startOf('second')
    .format('YYYY-MM-DD[T]HH:mm')
}

export const getDateString = (dateTime = new Date()) => dayjs(dateTime).format('YYYY-MM-DD')

export const getTime = (dateTime: ConfigType = new Date()) => dayjs(dateTime).format('HH:mm')

export const getTimeString = (dateTime: ConfigType = new Date()) => dayjs(dateTime).format('HH:mm')

export const joinDateTime = (date: ConfigType, time: ConfigType): Dayjs => dayjs(date + ' ' + time)

export const getDateTimeString = (dateTime: ConfigType = new Date()) => dayjs(dateTime).format('YYYY-MM-DDTHH:mm')

export const toJsonString = (dateTime: ConfigType = new Date()) => dayjs(dateTime).format('YYYY-MM-DDTHH:mm')

export const addMinutes = (datetime: ConfigType, minutes: number) =>
  dayjs(datetime)
    .add(minutes, 'minutes')
    .format('YYYY-MM-DD[T]HH:mm')

export const isDateValid = (date: ConfigType) => date && dayjs(date).isValid()
